import React from "react";
import { Container, Row, Col, Button } from "reactstrap";

function Stats() {
  return (
    <>
      <div
        style={{
          backgroundColor: "#010322",
        }}
        className="section"
        data-parallax={true}
      >
        <Container fluid>
          <Row className="justify-content-center">
            {content.map((c) => (
              <Col lg={3} className={`px-5 mt-4`}>
                <a href={c.link} target="_blank">
                  <img
                    src={require(`assets/images/logo/${c.image}`)}
                    width={"100%"}
                    style={{ maxWidth: "400px" }}
                    alt="Finnovex"
                  />
                </a>
              </Col>
            ))}
          </Row>
        </Container>
      </div>
    </>
  );
}

const content = [
  { image: "Finnovex-North-Africa-2024.png", link: "https://na.finnovex.com" },
  { image: "Finnovex-East-Africa-2024.png", link: "https://ea.finnovex.com" },
  { image: "Finnovex-Saudi-Arabia-2024.png", link: "https://ksa.finnovex.com" },
  { image: "Finnovex-Qatar-2024.png", link: "https://qa.finnovex.com" },
  { image: "Finnovex-South-Africa-2024.png", link: "https://sa.finnovex.com" },
  { image: "Finnovex-Middle-East-2024.png", link: "https://me.finnovex.com" },
  { image: "Finnovex-West-Africa-2024.png", link: "https://wa.finnovex.com" },
  // { image: "Finnovex-Europe-2024.png", link: "https://eu.finnovex.com" },
];

export default Stats;
